/* assistant-regular - latin */
@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/assistant-v5-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Assistant'), local('Assistant-Regular'),
    url('../fonts/assistant-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/assistant-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/assistant-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/assistant-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/assistant-v5-latin-regular.svg#Assistant') format('svg'); /* Legacy iOS */
}

html {
    width: 100%;
    height: 100%;
    overflow: auto !important;
    font-size: 16px;
}

body {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

#root {
    width: 100%;
    height: 100%;
}

.black { color: #000; }

.textRight { text-align: right; }
.textLeft { text-align: left; }
.textCenter { text-align: center; }

.inline { display: inline; }
.block { display: block; }
.inlineBlock { display: inline-block; }

.height100 { height: 100%; }

.width100 { width: 100%; }
.width80 { width: 80%; }
.width60 { width: 60%; }

.paddingBottom24 {
    padding-bottom: 24px;
}

.smallLabel {
    font-size: 12px;
}
.bold { font-weight: bold; }

.flexCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ant-upload-list-item-name {
    height: 32px;
    line-height: 32px;
}